import React from 'react';
import {css} from '@emotion/core';

const Article = (props) => {
	return (
		<a
			href={props.articleLink}
			css={css`
				display: inline-block;
				width: 25%;
				padding: 2.5vh 1.75vw;
				border-radius: 2.5vh;
				box-shadow: 0 3px 6px 0 #00000029;
				background-color: #ffffff;
				@media (max-width: 65rem) {
					box-shadow: none;
					margin: 0 7vw;
					margin-top: 4vh;
					width: 79%;
					padding: 0 1rem 0;
				}
			`}
			rel="noopener noreferrer"
			target="_blank"
		>
			<img
				css={css`
					width: 25vw;
					height: 25vh;
					border-radius: 1.25vh;
					margin: auto;
					@media (max-width: 65rem) {
						height: 12.5rem;
						width: 100%;
						display:none;
					}
				`}
				src={props.articleImage}
				alt="article"
			/>
			<div
				css={css`
					display: flex;
					flex: 1 1 auto;
					flex-direction: column;
					margin-top: 2vh;
					@media (max-width: 65rem) {
						padding-left: 0;
						margin: 1rem 0;
					}
				`}
			>
				<p
					css={css`
						color: black;
						font-size: 2.5vh;
						font-weight: bold;
						line-height: 3.75vh;
						@media (max-width: 65rem) {
							font-size: 1.25rem;
    line-height: 1.875rem;
						}
					`}
				>
					{props.heading}
				</p>
				<p
					css={css`
						margin-top: 1vh;
						font-size: 1.625vh;
    color: black;
    line-height: 2.75vh;
						@media (max-width: 65rem) {
							margin-top: 0.5rem;
							font-size: 0.8125rem;
							line-height: 1.375rem;
						}
					`}
				>
					{props.summary}
				</p>
				<div
					css={css`
						margin-top: 2vh;
						@media (max-width: 65rem) {
							margin-top: 1rem;
						}
					`}
				>
					<p
						css={css`
							font-size: 2vh;
							color: #80e8fe;
							line-height: 1.75vh;
						`}
					>
						{props.authorName}
					</p>
					<p
						css={css`
							color: #949494;
							font-size: 1.625vh;
							line-height: 3.75vh;
						`}
					>
						{props.publishedDate}
					</p>
				</div>
			</div>
		</a>
	);
};

export default Article;
