import React from 'react';
import {css} from '@emotion/core';
import useWindowDimensions from '../../hooks/useWindowDimensions';

const Testimonial = (props) => {
	const dimensions = useWindowDimensions();
	return (
		<div
			css={css`
				display: inline-flex;
				width: calc(${dimensions.width}px / 3.65);
				margin: auto 2.75vw;
				@media (max-width: 65rem) {
					width: 85%;
					margin: auto 6.675vw;
				}
			`}
		>
			<img
				css={css`
					align-self: flex-start;
					height: 112px;
					margin: 0 0 2vh;
					border-radius: 50%;
					border: 2px solid #80e8fe;
					@media (max-width: 65rem) {
						height: 80px;
						margin: 0;
					}
				`}
				src={require(`../../images/attestants/${props.attestantName
					.toString()
					.toLowerCase()}.jpg`)}
				alt="testimonial attestant"
			/>

			<div
				css={css`
					padding-left: 1.25vw;
					p {
						color: black;
						font-size: 1.625vh;
						line-height: 2.5vh;
						font-weight: 500;
						&:last-child {
							line-height: 3.75vh;
							margin-top: 0.5rem;
						}
					}
					@media (max-width: 65rem) {
						padding-left: 4.8vw;
						p {
							font-size: 0.8125rem;
							line-height: 1.25rem;
							&:last-child {
								line-height: 1.875rem;
							}
						}
					}
				`}
			>
				<p>{`"${props.testimonial}"`}</p>
				<p>{`- ${props.attestantName}`}</p>
			</div>
		</div>
	);
};

export default Testimonial;
