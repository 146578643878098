import React from 'react';
import {css} from '@emotion/core';
import {Link} from 'gatsby';
import {FeatureCardWrapper} from './components';

const FeatureCard = (props) => {
	return (
		<FeatureCardWrapper>
			<img
				src={props.featureIcon}
				alt={props.featureIconAlt}
				css={css`
					height: ${props.iconHeight}vh;
					margin: ${props.iconTop}vh 0 2.5vh;
				`}
			/>
			<img
				src={require(`../../images/icons/how-it-works-step-${props.index}.svg`)}
				alt={`step ${props.index}`}
				css={css`
					height: 6.125vh;
					width: 6.125vh;
					position: relative;
					position: absolute;
					right: -3vh;
					top: 45%;
				`}
			/>
			<p className="heading">{props.heading}</p>
			<p className="description">{props.desciption}</p>
			<Link to={props.toKnowMore}>
				<p>Know More</p>
			</Link>
		</FeatureCardWrapper>
	);
};

export default FeatureCard;
