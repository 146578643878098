import React, {useState} from 'react';
import {css} from '@emotion/core';
import Loadable from 'react-loadable';
import {useSwipeable} from 'react-swipeable';
import {
	SectionName,
	Article,
	FeatureCard,
	HorizontalFlexCardWrapper,
	RangeSlider,
	Testimonial,
	FooterSubSectionHeading,
	FooterSectionWrapper,
	FooterSubSectionContentWrapper,
	WhatsAppContactButton,
} from '../components/Index';
import useWindowDimensions from '../hooks/useWindowDimensions';
import HeroImage from '../components/lazy-hero-image';
import NavigationBar from '../components/navigation-bar';
import SideDrawer from '../components/SideDrawer/SideDrawer';
import Backdrop from '../components/Backdrop/Backdrop';
import depositMoneyIcon from '../images/icons/deposit-money.svg';
import taxBenefitIcon from '../images/icons/tax-benefit.svg';
import pensionCardIcon from '../images/icons/pension-card.svg';
import facebookLogo from '../images/icons/facebook-logo.svg';
import instagramLogo from '../images/icons/instagram-logo.svg';
import linkedinLogo from '../images/icons/linkedin-logo.svg';
import playstoreLogo from '../images/icons/playstore-logo.svg';
import youtubeLogo from '../images/icons/youtube-logo.svg';
import firstArticleImage from '../images/article-image-first.png';
import secondArticleImage from '../images/article-image-second.jpg';
import thirdArticleImage from '../images/article-image-third.jpg';
import founderIcon from '../images/icons/founder-autosave.svg';
import ycLogo from '../images/icons/y-combinator-logo.svg';
import savingsVessel from '../images/icons/savings-vessel.png';
import quoteBeginIcon from '../images/icons/quote-begin.svg';
import quoteEndIcon from '../images/icons/quote-end.svg';
import callUsIcon from '../images/icons/call-us-icon.svg';

const Loading = () => (
	<div
		css={css`
			margin: auto;
		`}
	>
		Loading...
	</div>
);

const Carousel = Loadable({
	loader: () => import('infinite-react-carousel'),
	loading: () => <Loading />,
});

const Index = (props) => {
	const [drawer, setDrawer] = useState({
		sideDrawerOpen: false,
		backdrop: '',
	});

	const backdropClickHandler = () => {
		setDrawer({...drawer, sideDrawerOpen: false});
	};

	const [savingsCalculatorValues, setSavingsCalculatorValues] = React.useState({
		age: 25,
		monthlySavings: 12000,
		monthlyCreditCardSpendings: 35000,
	});

	const {age, monthlySavings, monthlyCreditCardSpendings} = savingsCalculatorValues;

	const handleSavingsCalculatorValueChange = (name) => (event, newValue) => {
		setSavingsCalculatorValues({
			...savingsCalculatorValues,
			[name]: newValue,
		});
	};

	const tenure = 10;
	const inflation = 4;
	const remaining_time_to_save = 60 - age;
	const monthly_card_spend_at_60 =
		monthlyCreditCardSpendings * (1 + inflation / 100) ** remaining_time_to_save;
	const total_fund_needed_from_60_till_90 = monthly_card_spend_at_60 * 12 * 30;
	const actual_fund_needed_to_be_saved =
		total_fund_needed_from_60_till_90 /
		((12 * [(1 + tenure / 100) ** (remaining_time_to_save - 1)]) / [1 + tenure / 100 - 1]);
	const isGoodSavings = actual_fund_needed_to_be_saved - monthlySavings <= 0;
	const drawerToggleClickHandler = () => {
		drawer.sideDrawerOpen
			? setDrawer({
					sideDrawerOpen: false,
					backdrop: '',
			  })
			: setDrawer({
					sideDrawerOpen: true,
					backdrop: <Backdrop click={backdropClickHandler} />,
			  });
	};

	const handleDrawerClose = (val) => {
		setDrawer({...drawer, sideDrawerOpen: val, backdrop: ''});
	};

	const dimensions = useWindowDimensions();
	let amplitude;
	if (typeof window !== 'undefined') {
		amplitude = require('amplitude-js/amplitude');
	}
	return (
		<React.Fragment>
			<NavigationBar drawerClickHandler={drawerToggleClickHandler} />
			{/* <SideDrawer drawerOpen={handleDrawerClose} show={drawer.sideDrawerOpen} />
	{drawer.backdrop} */}
			<section
				css={css`
					min-height: calc(100vh - 11vh);
					background-color: #80e8fe;
					margin-top: 11vh;
					/* A very fragile workaround */
					@media (max-width: 65rem) {
						min-height: calc(100vh - 8.4705vh);
						margin-top: 8.4705vh;
						.gatsby-image-wrapper {
							top: 8.4705vh !important;
						}
						.gatsby-image-wrapper picture img {
							height: auto !important;
						}
					}
				`}
			>
				<div
					css={css`
						position: relative;
						top: 10vh;
						left: 7vw;
						width: 49vw;
						@media (max-width: 65rem) {
							left: 0;
							top: 65vw;
    						z-index: 1;
							width: 100%;
							p {
								text-align: left;
								margin: auto;
								padding: 0 1.875rem;
							}
						}
					`}
				>
					<p
						css={css`
							font-size: 5vh;
							font-weight: bold;
							line-height: 6.75vh;
							color: black;
							@media (max-width: 65rem) {
								font-size: 1.25rem;
								line-height: 1.68rem;
							}
						`}
					>
						Helping non-govt employees have regular pension.
					</p>
					<p
						css={css`
							font-size: 2.5vh;
							line-height: 3.75vh;
							margin-top: 1.75vh;
							color: black;
							@media (max-width: 65rem) {
								font-size: 0.8125rem;
								line-height: 1.25rem;
							}
						`}
					>
						You will have a National Pension System Card (NPS). Your pension savings
						enjoy tax benefit. 3 crore central, state and public sector employees have
						pension from NPS.
					</p>
				</div>
				<HeroImage />
				<WhatsAppContactButton
					css={css`
						position: absolute;
						left: 50%;
						bottom: 7vh;
						transform: translateX(-50%);
					`}
					width={25}
					href="https://api.whatsapp.com/send?phone=14159807488"
					onClick={() => amplitude.getInstance().logEvent('Hero Button Clicked')}
				>
					Click to WhatsApp us
				</WhatsAppContactButton>
			</section>
			<section
				css={css`
					background: linear-gradient(to bottom, #80e8fe 50%, white 50%);
					@media (max-width: 65rem) {
						background: linear-gradient(
								to bottom,
								#80e8fe 25%,
								white 25% 85%,
								#80e8fe 85% 100%
							)
							100% no-repeat;
					}
				`}
				id="how-it-works"
			>
				<SectionName>How it works</SectionName>
				<div
					css={css`
						position: relative;
						padding-top: 5vh;
						width: 100%;
						display: flex;
						flex-wrap: wrap;
						flex-direction: column;
						justify-content: space-evenly;
						@media (min-width: 64.99rem) {
							flex-direction: row;
							padding: 10vh 0 20vh;
						}
					`}
				>
					<FeatureCard
						index={1}
						featureIcon={pensionCardIcon}
						featureIconAlt="pension card"
						iconHeight={13}
						iconTop={4}
						heading="Get pension card"
						toKnowMore="#"
						desciption="Whatsapp us your phone no and we will collect your information and
						open an account for you in 5 days."
					/>
					<FeatureCard
						index={2}
						featureIcon={depositMoneyIcon}
						featureIconAlt="deposit"
						iconHeight={14}
						iconTop={4}
						heading="Deposit Money"
						toKnowMore="#"
						desciption="Deposit money in your pension account anytime via AutoSave. You get
						SMS when money is deposited."
					/>
					<FeatureCard
						index={3}
						featureIcon={taxBenefitIcon}
						featureIconAlt="tax benefit"
						iconHeight={7.75}
						iconTop={10}
						heading="Tax Benefit"
						toKnowMore="#"
						desciption="Get tax saving benefit upto 50,000 when you save for pension.We will
						help you claim it."
					/>
				</div>
			</section>
			<section
				id="news"
				css={css`
					background-color: #80e8fe;
				`}
			>
				<SectionName>Explore News</SectionName>
				<div
					css={css`
						padding: 6vh 0 7.5vh;
						width: 100%;
						display: flex;
						flex-wrap: wrap;
						flex-direction: row;
						justify-content: space-evenly;
						background-color: #80e8fe;
						@media (max-width: 65rem) {
							display: none;
						}
					`}
				>
					<Article
						heading="What is the National Pension System and how do you open an NPS account?"
						summary="Currently, any Indian resident between the age of 18 to 60 years can open an NPS account and save up ₹1.5 lakh per annum under section 80C of the Income Tax Act. This includes investments in insurance plans and other select instruments — including buying a pension plan."
						articleImage={firstArticleImage}
						authorName="Business Insider"
						publishedDate="Jan 17, 2020"
						articleLink="https://www.businessinsider.in/personal-finance/budget-2020-what-is-the-national-pension-system-and-how-do-you-open-an-nps-account/amp_articleshow/73324503.cms"
					/>
					<Article
						heading="NPS Posts 35% Subscriber Growth In 2019"
						summary="India pension scheme administered and regulated by the Pension Fund Regulatory and Development Authority (PFRDA) posted 35 per cent subscriber growth so far during the financial year 2019-20. As far as Maharashtra is concerned, NPS has been gowning steadily in the state and has become second largest state having maximum number of subscribers."
						articleImage={secondArticleImage}
						authorName="Outlook Money"
						publishedDate="Dec 22, 2019"
						articleLink="https://www.outlookindia.com/outlookmoney/amp/finance/nps-posts-35-subscriber-growth-in-2019-4071"
					/>
					<Article
						heading="PFRDA wants government to double tax benefit under NPS to Rs 1 lakh"
						summary="The Pension Fund Regulatory and Development Authority of India (PFRDA) has also urged the government to extend the facility of of tax free contribution of 14 per cent by the central government to its employees under the NPS to all categories of subscribers."
						articleImage={thirdArticleImage}
						authorName="Economic Times"
						publishedDate="Dec 23, 2019"
						articleLink="https://m.economictimes.com/news/economy/policy/pfrda-wants-government-to-double-tax-benefit-under-nps-to-rs-1-lakh/amp_articleshow/72941734.cms"
					/>
				</div>
				<Carousel
					arrows={false}
					dots
					autoplay={false}
					slidesPerRow={1}
					css={css`
						z-index: 1;
						.carousel-dots .carousel-dots-active button:before {
							color: white;
						}
						.carousel-dots {
							margin-top: 2em;
						}
						@media (min-width: 64.99rem) {
							display: none;
						}
					`}
				>
					<Article
						heading="What is the National Pension System and how do you open an NPS account?"
						summary="Currently, any Indian resident between the age of 18 to 60 years can open an NPS account and save up ₹1.5 lakh per annum under section 80C of the Income Tax Act. This includes investments in insurance plans and other select instruments — including buying a pension plan."
						articleImage={firstArticleImage}
						authorName="Business Insider"
						publishedDate="Jan 17, 2020"
						articleLink="https://www.businessinsider.in/personal-finance/budget-2020-what-is-the-national-pension-system-and-how-do-you-open-an-nps-account/amp_articleshow/73324503.cms"
					/>
					<Article
						heading="NPS Posts 35% Subscriber Growth In 2019"
						summary="India pension scheme administered and regulated by the Pension Fund Regulatory and Development Authority (PFRDA) posted 35 per cent subscriber growth so far during the financial year 2019-20.NPS has been gowning steadily in the state and has become second largest state having maximum number of subscribers."
						articleImage={secondArticleImage}
						authorName="Outlook Money"
						publishedDate="Dec 22, 2019"
						articleLink="https://www.outlookindia.com/outlookmoney/amp/finance/nps-posts-35-subscriber-growth-in-2019-4071"
					/>
					<Article
						heading="PFRDA wants government to double tax benefit under NPS to Rs 1 lakh"
						summary="The Pension Fund Regulatory and Development Authority of India (PFRDA) has also urged the government to extend the facility of of tax free contribution of 14 per cent by the central government to its employees under the NPS to all categories of subscribers."
						articleImage={thirdArticleImage}
						authorName="Economic Times"
						publishedDate="Dec 23, 2019"
						articleLink="https://m.economictimes.com/news/economy/policy/pfrda-wants-government-to-double-tax-benefit-under-nps-to-rs-1-lakh/amp_articleshow/72941734.cms"
					/>
				</Carousel>
			</section>
			<section
				css={css`
					background: linear-gradient(to bottom, #80e8fe 63%, white 37%);
				`}
			>
				<SectionName>Who we are</SectionName>
				<div
					css={css`
						padding: 14.25vh 0 12.5vh;
						width: 100%;
						display: flex;
						flex-wrap: nowrap;
						flex-direction: row;
						justify-content: center;
						@media (max-width: 65rem) {
							justify-content: space-around;
							padding: 9.3vh 0 5.75vh;
						}
					`}
				>
					<HorizontalFlexCardWrapper
						css={css`
							padding: 3vh 4vw;
							@media (max-width: 65rem) {
								padding: 0.75rem;
								width: 50%;
							}
						`}
					>
						<p
							css={css`
								color: black;
								text-align: center;
								font-size: 2.5vh;
								font-weight: bold;
								line-height: 3.75vh;
								@media (max-width: 65rem) {
									font-size: 1.25rem;
									line-height: 1.875rem;
								}
							`}
						>
							Smruti Parida
						</p>
						<img
							src={founderIcon}
							alt="Founder, AutoSave"
							css={css`
								height: 16vh;
								display: block;
								margin: 2vh auto;
								@media (max-width: 65rem) {
									height: 5.6875rem;
									margin: 0.6875rem auto;
								}
							`}
						/>
						<p
							css={css`
								color: black;
								text-align: center;
								font-size: 1.625vh;
								line-height: 2.25vh;
								@media (max-width: 65rem) {
									font-size: 0.8125rem;
									line-height: 1.125rem;
								}
							`}
						>
							CoFounder, ex-CTO @NestAway <br />
							Bengaluru, Karnataka, India
						</p>
					</HorizontalFlexCardWrapper>
					<HorizontalFlexCardWrapper
						css={css`
							padding: 4vh 2.5vw;
							margin: auto 0 auto 4vw;
							height: fit-content;
							@media (max-width: 65rem) {
								padding: 1rem 0.5rem;
								width: 26%;
								margin: auto 0 auto 0;
							}
						`}
					>
						<img
							src={ycLogo}
							alt="Y Combinator Logo"
							css={css`
								height: 7vh;
								display: block;
								margin: 0 auto 2vh;
								@media (max-width: 65rem) {
									height: 2.125rem;
									margin: 0.5rem auto;
								}
							`}
						/>
						<p
							css={css`
								color: #fb651e;
								font-size: 2.5vh;
								line-height: 3.375vh;
								text-align: center;
								font-weight: 600;
								@media (max-width: 65rem) {
									font-size: 0.75rem;
									line-height: 1rem;
								}
							`}
						>
							Y Combinator
						</p>
					</HorizontalFlexCardWrapper>
				</div>
			</section>
			<section>
				<SectionName color="primary">Calculate & Get started</SectionName>
				<div
					css={css`
						padding: 15vh 0 15vh;
						width: 100%;
						display: flex;
						flex-wrap: wrap;
						flex-direction: row;
						justify-content: center;
						@media (max-width: 65rem) {
							padding: 5.25vh 0 0;
						}
					`}
				>
					<HorizontalFlexCardWrapper
						css={css`
							width: 32%;
							@media (max-width: 65rem) {
								width: 85%;
							}
						`}
					>
						<div
							css={css`
								padding: 7vh 5vw;
								display: flex;
								flex-wrap: wrap;
								flex-direction: column;
								justify-content: space-evenly;
								p {
									color: black;
									font-size: 1.625vh;
									line-height: 3.75vh;
									span {
										font-size: 2.25vh;
										color: black;
										line-height: 3.75vh;
										font-weight: bold;
									}
								}
								@media (max-width: 65rem) {
									padding: 4.5vh 6.5vw 4.5vh;
									p {
										font-size: 0.8125rem;
										line-height: 1.875rem;
										span {
											font-size: 1.125rem;
											line-height: 1.875rem;
										}
									}
								}
							`}
						>
							<p>
								I am <span>{age}</span> years old
							</p>
							<RangeSlider
								name="age"
								value={age}
								onChange={handleSavingsCalculatorValueChange('age')}
								valueLabelDisplay="auto"
								defaultValue={25}
								marks={[
									{
										value: 0,
										label: '0',
									},
									{
										value: 100,
										label: '100',
									},
								]}
							/>
							<p>
								Every month I save <span>{`\u20B9${monthlySavings}`}</span>
							</p>
							<RangeSlider
								name="monthlySavings"
								value={monthlySavings}
								onChange={handleSavingsCalculatorValueChange('monthlySavings')}
								valueLabelDisplay="auto"
								defaultValue={12000}
								min={0}
								max={60000}
								step={1000}
								marks={[
									{
										value: 0,
										label: '0',
									},
									{
										value: 60000,
										label: '60,000',
									},
								]}
							/>
							<p>
								My credit card spend is{' '}
								<span>{`\u20B9${monthlyCreditCardSpendings}`}</span>
							</p>
							<RangeSlider
								name="monthlyCreditCardSpendings"
								value={monthlyCreditCardSpendings}
								onChange={handleSavingsCalculatorValueChange(
									'monthlyCreditCardSpendings',
								)}
								valueLabelDisplay="auto"
								defaultValue={35000}
								min={0}
								max={100000}
								step={1000}
								marks={[
									{
										value: 0,
										label: '0',
									},
									{
										value: 100000,
										label: '1,00,000',
									},
								]}
							/>
						</div>
					</HorizontalFlexCardWrapper>
					<div
						css={css`
							margin: auto 0 auto 10vw;
							height: fit-content;
							display: flex;
							@media (max-width: 65rem) {
								width: 85%;
								margin: 8.75vh auto 5vh auto;
							}
						`}
					>
						<div
							css={css`
								position: relative;
							`}
						>
							<img
								css={css`
									width: 12vw;
									height: 28vh;
									@media (max-width: 65rem) {
										width: 35.5vw;
									}
								`}
								src={savingsVessel}
								alt="savings vessel"
							/>
							<div
								className="savings-level"
								css={css`
									background-color: #80e8fe;
									position: absolute;
									bottom: 0.75vh;
									left: 50%;
									transform: translateX(-50%);
									width: 11.5vw;
									max-height: 27.5vh;
									height: calc(
										28vh *
											(${monthlySavings} / ${actual_fund_needed_to_be_saved})
									);
									transition: all 3s ease-out;
									background-position: top left;
									z-index: -1;
									@media (max-width: 65rem) {
										width: 34.5vw;
									}
								`}
							/>
						</div>
						<div
							css={css`
								p {
									font-size: 1.875vh;
									line-height: 3.75vh;
									font-weight: bold;
									width: 20vw;
									color: black;
									@media (max-width: 65rem) {
										font-size: 0.75rem;
										line-height: 1.5rem;
										width: 51vw;
									}
									span {
										font-size: 2vh;
										font-weight: bold;
										line-height: 3vh;
										@media (max-width: 65rem) {
											font-size: 0.8125rem;
											line-height: 1.25rem;
										}
									}
								}
							`}
						>
							<p
								css={css`
									padding-left: 4.2vw;
								`}
							>
								How much you need to retire?
								<br />
								<span>
									{Math.round(total_fund_needed_from_60_till_90).toLocaleString(
										'en-IN',
									)}
								</span>
							</p>
							<p
								css={css`
									position: relative;
									top: 50%;
									transform: translateY(-50%);
								`}
							>
								{isGoodSavings && monthlyCreditCardSpendings !== 0 ? (
									'Perfect! you are already saving enough. You can consider locking that saving for your pension!'
								) : (
									<React.Fragment>
										How much you save for retirement?
										<br />
										<span>
											{Math.round(
												actual_fund_needed_to_be_saved,
											).toLocaleString('en-IN')}{' '}
											per month
										</span>
									</React.Fragment>
								)}
							</p>
						</div>
					</div>
				</div>
			</section>
			<section>
				<SectionName color="primary" top="0">
					Testimonials
				</SectionName>
				<div
					css={css`
						display: flex;
						flex-direction: column;
						position: relative;
						margin-top: 3.75vh;
						width: 100%;
						background: #fafafa;
						clip-path: polygon(0% 0%, 100% 15%, 100% 100%, 0% 85%);
					`}
				>
					<img
						css={css`
							align-self: flex-start;
							height: 4.4vh;
							width: 3.4vw;
							margin: 5vh 0 10vh 3.75vw;
							@media (max-width: 65rem) {
								margin: 3vh 0 4.75vh 6.7vw;
								width: 1.875rem;
								height: 1.5rem;
							}
						`}
						src={quoteBeginIcon}
						alt="quote begin"
					/>
					<Carousel
						arrows={false}
						dots
						autoplay
						slidesPerRow={dimensions.width <= 1040 ? 1 : 3}
						autoplaySpeed={10000}
						css={css`
							z-index: 1;
							.carousel-dots .carousel-dots-active button:before {
								color: #80e8fe;
							}
							.carousel-dots {
								margin-top: 2em;
							}
						`}
					>
						<Testimonial
							testimonial="I always wished to have a pension like my parents. But never knew we can have NPS card until I found AutoSave through a friend. It is a super useful service. Everything works in whatsapp. I am quite satisfied and will use them every month."
							attestantName="Ankush"
						/>
						<Testimonial
							testimonial="AutoSave helped me have a retirement fund. I have now moved my PPF savings to NPS using AutoSave. Never knew once can use this government scheme for corporate employees. Wonderful concept!!"
							attestantName="Tuhina"
						/>
						<Testimonial
							testimonial="There was always a plan to save money. But a dedicated pension savings is so much peace of mind. I get regular updates now. The savings are safe and done same way the government employees do.  That is so relaxing to know."
							attestantName="Gill"
						/>
						<Testimonial
							testimonial="I am in a job now, and I found that I should save more than my credit card spend to be able to retire around 55. Thanks to the retirement calculator tool. I am happy that there is autosave to let people do NPS savings. Good job!!"
							attestantName="Hasan"
						/>
						<Testimonial
							testimonial="I am just 25 but then I think having a saving is always good. I think my parents are doing so cool with a pension. I want the same for me, and I am saving via autosave monthly."
							attestantName="Dev"
						/>
					</Carousel>
					<img
						css={css`
							align-self: flex-end;
							height: 4.4vh;
							width: 3.4vw;
							margin: 2vh 3.75vw 5vh 0;
							@media (max-width: 65rem) {
								visibility: hidden;
							}
						`}
						src={quoteEndIcon}
						alt="quote end"
					/>
				</div>
				<div
					css={css`
						position: relative;
						margin: 14.75vh 0;
						width: 100%;
						display: flex;
						flex-direction: row;
						color: black;
						justify-content: space-around;
						align-items: center;
						@media (max-width: 65rem) {
							flex-direction: column;
							margin: 8vh 0px 10vh;
						}
					`}
				>
					<p
						css={css`
							font-size: 3.75vh;
							line-height: 3.75vh;
							font-weight: bold;
							@media (max-width: 65rem) {
								font-size: 1.25rem;
								line-height: 1.875rem;
								margin-bottom: 3.25vh;
							}
						`}
					>
						We're here to help you
					</p>

					<div
						css={css`
							display: inline-flex;
						`}
					>
						<img
							css={css`
								align-self: flex-start;
								height: 4.5vh;
							`}
							src={callUsIcon}
							alt="call us"
						/>
						<div
							css={css`
								padding-left: 1.5vw;
								p {
									line-height: 2.75vh;
								}
							`}
						>
							<p
								css={css`
									color: black;
									font-size: 2.5vh;
									font-weight: bold;
									text-align: center;
									@media (max-width: 65rem) {
										font-size: 1.25rem;
										line-height: 1.875rem;
									}
								`}
							>
								9611594353
							</p>
							<p
								css={css`
									color: #a2a0a0;
									font-size: 1.875vh;
									@media (max-width: 65rem) {
										font-size: 0.875rem;
										line-height: 1.875rem;
										margin-bottom: 3.25vh;
									}
								`}
							>
								6am - 6pm, Mon - Fri
							</p>
						</div>
					</div>
					<WhatsAppContactButton
						width={25}
						href="https://api.whatsapp.com/send?phone=14159807488"
						onClick={() => amplitude.getInstance().logEvent('Footer Button Clicked')}
					>
						Click to WhatsApp us
					</WhatsAppContactButton>
				</div>
			</section>
			<footer
				css={css`
					width: 100%;
					background-color: #052a43;
					padding: 7.75vh 0 7vh;
					position: relative;
					display: flex;
					flex-direction: row;
					justify-content: space-around;
					@media (max-width: 65rem) {
						flex-direction: column-reverse;
						padding: 2.5rem 0 0;
					}
				`}
			>
				<FooterSectionWrapper>
					<div
						css={css`
							width: 23vw;
							@media (max-width: 65rem) {
								width: 90%;
								margin: 0 5.5vw 5.5vw 5.5vw;
							}
						`}
					>
						<FooterSubSectionHeading>AutoSave</FooterSubSectionHeading>
						<FooterSubSectionContentWrapper>
							Autosave intends to enable every indian have enough saving so that 20
							years from today they will have a pension that will secure their
							financial health.
						</FooterSubSectionContentWrapper>
					</div>
					<div
						css={css`
							@media (max-width: 65rem) {
								display: flex;
								flex-direction: row-reverse;
								margin: 0 5.5vw 4.5vw;
								justify-content: space-between;
							}
						`}
					>
						<div>
							<FooterSubSectionHeading>Email Us</FooterSubSectionHeading>
							<FooterSubSectionContentWrapper>
								support@autosave.in
							</FooterSubSectionContentWrapper>
						</div>
						<div
							css={css`
								@media (max-width: 65rem) {
									margin-right: 4.5vw;
								}
							`}
						>
							<FooterSubSectionHeading>Address</FooterSubSectionHeading>
							<FooterSubSectionContentWrapper>
								1608 , Urban vault spaces, <br />
								19th main, HSR Layout, <br />
								Bangalore, 560102 Karnataka , India
							</FooterSubSectionContentWrapper>
						</div>
					</div>
				</FooterSectionWrapper>
				<FooterSectionWrapper
					css={css`
						@media (max-width: 65rem) {
							flex-direction: row-reverse;
							margin: 0 5.5vw 4.5vw;
							justify-content: space-between;
						}
					`}
				>
					<div>
						<FooterSubSectionHeading>Company</FooterSubSectionHeading>
						<FooterSubSectionContentWrapper>
							<ul>
								<li>About us</li>
								<li>Blog</li>
								<li>Contact us</li>
							</ul>
						</FooterSubSectionContentWrapper>
					</div>
					<div
						css={css`
							@media (max-width: 65rem) {
								margin: 0 4.5vw;
								width: calc(100vw / 3);
							}
						`}
					>
						<FooterSubSectionHeading>Resources</FooterSubSectionHeading>
						<FooterSubSectionContentWrapper>
							<ul>
								<li>Pension</li>
								<li>FAQ</li>
							</ul>
						</FooterSubSectionContentWrapper>
					</div>
					<div>
						<FooterSubSectionHeading>Legal</FooterSubSectionHeading>
						<FooterSubSectionContentWrapper>
							<ul>
								<li>Terms of services</li>
								<li>Referral Terms and Conditions</li>
								<li>Privacy Policy</li>
							</ul>
						</FooterSubSectionContentWrapper>
					</div>
				</FooterSectionWrapper>
				<FooterSectionWrapper
					css={css`
						position: relative;
						top: -6vh;
						@media (max-width: 65rem) {
							margin: 4vh 5.5vw 0;
						}
					`}
				>
					<div>
						<FooterSubSectionHeading>Get it on</FooterSubSectionHeading>
						<FooterSubSectionContentWrapper>
							<img
								src={playstoreLogo}
								alt="playstore"
								css={css`
									height: 10vh;
									@media (max-width: 65rem) {
										height: 3.75rem;
									}
								`}
							/>
						</FooterSubSectionContentWrapper>
					</div>
					<div>
						<FooterSubSectionHeading>Connect with us</FooterSubSectionHeading>
						<FooterSubSectionContentWrapper
							css={css`
								display: flex;
								justify-content: space-between;
								img {
									height: 6vh;
								}
								@media (max-width: 65rem) {
									justify-content: space-between;
									img {
										height: 2.1875rem;
									}
								}
							`}
						>
							<img src={facebookLogo} alt="facebook" />
							<img src={instagramLogo} alt="instagram" />
							<img src={youtubeLogo} alt="twitter" />
							<img src={linkedinLogo} alt="linkedin" />
						</FooterSubSectionContentWrapper>
					</div>
				</FooterSectionWrapper>
			</footer>
		</React.Fragment>
	);
};

export default Index;
