import styled from '@emotion/styled';
import Slider from '@material-ui/core/Slider';

export const SectionName = styled.p`
	padding: ${(props) => props.top || '11.25vh'} 0 0.5vh;
	margin: auto;
	color: ${(props) => (props.color === 'primary' ? '#80e8fe' : 'white')};
	font-size: 3.75vh;
	font-weight: bold;
	line-height: 3.75vh;
	border-bottom: 2px solid;
	border-bottom-color: ${(props) => (props.color === 'primary' ? '#80e8fe' : 'white')};
	@media (max-width: 65rem) {
		font-size: 1.25rem;
		line-height: 1.875rem;
		padding: 9vh 0 0.5vh;
	}
	text-align: center;
	width: fit-content;
	${(props) => props.overrideStyles}
`;

export const ArticleWrapper = styled.div`
	margin: 2.25vh auto;
	display: inline-flex;
	width: 85%;
	padding: 5vh 3.75vw;
	border-radius: 2.5vh;
	box-shadow: 0 3px 6px 0 #00000029;
	background-color: #ffffff;
	@media (max-width: 65rem) {
		width: 79%;
		flex-direction: column;
		padding: 1.375rem 1rem 0;
	}
`;

export const FeatureCardWrapper = styled.div`
	position: relative;
	display: flex;
	background: white;
	border-radius: 2.5vh;
	width: 26vw;
	flex-direction: column;
	box-shadow: 0 0 6px 0 #00000029;
	@media (max-width: 65rem) {
		margin: 8vw auto;
		width: 80%;
	}
	.heading {
		margin: auto;
		font-size: 2.5vh;
		font-weight: 600;
		line-height: 3.75vh;
		color: black;
		@media (max-width: 65rem) {
			font-size: 1rem;
			line-height: 1.5rem;
		}
	}
	.description {
		text-align: center;
		color: black;
		padding: 1.5vh 5vh 2.75vh;
		font-size: 1.625vh;
		line-height: 2.5vh;
		@media (max-width: 65rem) {
			font-size: 0.8125rem;
			line-height: 1.25rem;
		}
	}
	a {
		font-size: 1.625vh;
		color: white;
		background-color: #80e8fe;
		width: 7.5vw;
		height: 4.5vh;
		box-shadow: 0 3px 6px 0 #00000029;
		border-radius: 1.25vh;
		margin: auto;
		position: relative;
		bottom: -5%;
		@media (max-width: 65rem) {
			width: 28%;
			margin-bottom: -5%;
		}
		p {
			width: 100%;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			line-height: 3.75vh;
			text-align: center;
		}
	}
`;

export const HorizontalFlexCardWrapper = styled.div`
	border-radius: 1.25vh;
	box-shadow: 0 3px 6px 0 #00000029;
	background-color: #ffffff;
`;

export const RangeSlider = styled(Slider)`
	margin: 25px auto 60px;
	@media (max-width: 65rem) {
		width: 85%;
		margin: 24px 0 60px 0;
	}
	&& {
		color: #80e8fe;
		height: 8px;
	}
	& .MuiSlider-thumb {
		height: 15px;
		width: 15px;
		background-color: white;
		border: 3px solid;
		margin-top: 0px;
		margin-left: -4px;
		&:focus,
		&:hover,
		&:active {
			box-shadow: none;
		}
	}
	& .MuiSlider-valueLabel {
		left: calc(-50% + -8px);
		top: -24px;
		color: #80e8fe;
		background: transparent;
		& * {
			color: #80e8fe;
			background-color: transparent;
			font-size: 1.25rem;
		}
	}

	& .MuiSlider-track {
		height: 8px;
		border-radius: 20px;
		margin-top: 4px;
		margin-left: 10px;
	}
	& .MuiSlider-rail {
		width: 105%;
		height: 16px;
		border-radius: 20px;
		opacity: 1;
		background-color: #f5f5f5;
	}
	& .MuiSlider-mark {
		background-color: transparent;
	}
	& .MuiSlider-markLabel {
		color: #bcbcbc;
		font-family: Nunito;
		font-size: 15px;
		font-weight: bold;
		line-height: 30px;
	}
	&:last-child {
		margin-bottom: 1.5vh;
	}
`;

export const FooterSectionWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
`;

export const FooterSubSectionHeading = styled.p`
	font-size: 2.5vh;
	font-weight: 500;
	line-height: 3.75vh;
	color: #80e8fe;
	@media (max-width: 65rem) {
		font-size: 0.9375rem;
		line-height: 1.875rem;
	}
`;
export const FooterSubSectionContentWrapper = styled.div`
	font-size: 1.875vh;
	line-height: 3.75vh;
	color: white;
	font-weight: 500;
	margin-top: 1vh;
	@media (max-width: 65rem) {
		font-size: 0.8125rem;
		line-height: 1.25rem;
	}
`;

export const WhatsAppContactButton = styled.a`
	box-shadow: 0 3px 4px 0 #00000029;
	background-color: white;
	padding: 3vh 0;
	color: black;
	text-align: center;
	text-decoration: none;
	justify-content: center;
	width: ${(props) => props.width}vw;
	border: solid 1px #80e8fe;
	border-radius: 1.25vh;
	@media (max-width: 65rem) {
		width: 83%;
		padding: 1.25rem 0;
	}
`;
