import React from 'react';
import {graphql, useStaticQuery} from 'gatsby';
import Img from 'gatsby-image';

const HeroImage = () => {
	const data = useStaticQuery(graphql`
		query {
			mobileImage: file(relativePath: {eq: "images/hero-image-mobile.png"}) {
				childImageSharp {
					fluid(quality: 100, maxWidth: 500) {
						...GatsbyImageSharpFluid
					}
				}
			}
			desktopImage: file(relativePath: {eq: "images/hero-image.png"}) {
				childImageSharp {
					fluid(quality: 100, maxWidth: 1280) {
						...GatsbyImageSharpFluid
					}
				}
			}
		}
	`);
	const sources = [
		data.mobileImage.childImageSharp.fluid,
		{
			...data.desktopImage.childImageSharp.fluid,
			media: `(min-width: 64.99rem)`,
		},
	];

	return (
		<Img
			fluid={sources}
			style={{
				position: 'absolute',
				left: 0,
				top: '17.75vh',
				bottom: 0,
				width: '100%',
				height: 'auto',
			}}
		/>
	);
};

export default HeroImage;
