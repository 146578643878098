import React from 'react';
import {css} from '@emotion/core';

const Hamburger = (props) => (
	<button
		type="button"
		css={css`
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			height: 3vh;
			width: 3.1vh;
			background: transparent;
			div {
				width: 2.2vh;
				height: 0.37vh;
				background: black;
				&:nth-child(2) {
					width: 3.1vh;
				}
			}
		`}
		onClick={props.click}
	>
		<div />
		<div />
		<div />
	</button>
);

export default Hamburger;
