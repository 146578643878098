import React from 'react';
import {Link} from 'gatsby';
import {css} from '@emotion/core';

import Hamburger from './SideDrawer/hamburger';
import logo from '../assets/logo.svg';

const NavigationBar = (props) => {
	let amplitude;
	if (typeof window !== 'undefined') {
		amplitude = require('amplitude-js/amplitude');
	}
	return (
		<header
			css={css`
				position: fixed;
				top: 0;
				left: 0;
				width: 100%;
				background: #f8f8f8;
				height: 11vh;
				z-index: 2;
				@media (max-width: 65rem) {
					height: 8.4705vh;
				}
			`}
		>
			<nav
				css={css`
					display: flex;
					height: 100%;
					align-items: center;
					padding: 0 3.90625vw;
				`}
			>
				<div
					css={css`
						outline: none;
						position: fixed;
						left: 1.5rem;
						display: none;
						@media (min-width: 64.99rem) {
							display: none;
						}
					`}
				>
					<Hamburger click={props.drawerClickHandler} />
				</div>

				<div
					css={css`
						display: inline-flex;
						@media (max-width: 65rem) {
							margin: auto;
							a {
								font-size: 1.125rem;
								line-height: 1.5rem;
								font-weight: bold;
							}
						}
					`}
				>
					<img
						alt="AutoSave Logo"
						src={logo}
						css={css`
							@media (max-width: 65rem) {
								display: none;
							}
							height: 5.75vh;
							width: 3.5165vw;
							margin-right: 1.45vw;
						`}
					/>
					<Link
						to="/"
						css={css`
							color: #80e8fe;
							font-size: 5vh;
							font-family: Nunito;
							line-height: 6.75vh;
							span {
								color: black;
							}
						`}
					>
						<span>Auto</span>Save
					</Link>
				</div>
				<div
					css={css`
						width: 100%;
						ul {
							display: flex;
							float: right;
							align-items: stretch;
							justify-content: space-between;
							width: 65%;
						}
						@media (max-width: 65rem) {
							display: none;
						}
					`}
				>
					<ul
						css={css`
							li {
								a {
									font-size: 2.25vh;
									font-family: Nunito;
									color: black;
									&:hover,
									&:active {
										color: #80e8fe;
									}
								}
								&:last-child a {
									width: 5.8vh;
									height: 6.25vh;
									border-radius: 5vh;
									background-color: #80e8fe;
									color: white;
									outline: none;
									padding: 1.5vh 4vh;
									font-weight: bold;
									box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
								}
							}
						`}
					>
						<li>
							<Link to="/#how-it-works">How it works?</Link>
						</li>
						<li>
							<Link to="/#news">FAQ</Link>
						</li>
						<li>
							<Link to="/#news">Resources</Link>
						</li>
						<li>
							<a
								href="https://api.whatsapp.com/send?phone=14159807488"
								onClick={() =>
									amplitude.getInstance().logEvent('Get-Started Button Clicked')}
							>
								Get Started
							</a>
						</li>
					</ul>
				</div>
			</nav>
		</header>
	);
};

export default NavigationBar;
